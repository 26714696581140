@import 'colors';

tui-hint {
  border-radius: 4px !important;
  padding: 12px 18px !important;

  &[data-appearance='custom'] {
    background: @background-color-hint;
  }
}
